import firebase from 'firebase/app';
import padStart from 'lodash/padStart';
import moment, { Moment } from 'moment-timezone';
import { useMemo } from 'react';
import {
  DAY_FORMAT,
  Timeframe,
  TIMEKEY_FORMAT
} from '../../domainTypes/analytics';
import { AnalyticsIntervalUnit } from '../../domainTypes/analytics_v2';
import { pluralize } from '../pluralize';

const DEFAULT_TZ = 'UTC';

export const ONE_MINUTE = 1 * 1000 * 60;
export const FIFTY_MINUTES = ONE_MINUTE * 50;
export const ONE_DAY = ONE_MINUTE * 60 * 24;

export const SIMPLE_DATE = 'MMM D';
export const PRECISE_DATE = 'YYYY/MM/DD HH:mm';
export const HUMAN_DATE = 'MMM D, YYYY (HH:mm)';
export const HUMAN_DATE_PRECISE = 'LLL z';
export const HUMAN_DATE_SHORT = 'MMM D, YYYY';

export const guessCurrentTimezone = () => {
  return moment.tz.guess() || DEFAULT_TZ;
};

export const getTzAbbr = (timezone: string) => {
  const zone = moment.tz.zone(timezone);
  return zone ? zone.abbr(Date.now()) : timezone;
};

export const useTzAbbr = (tz: string) => {
  return useMemo(() => getTzAbbr(tz), [tz]);
};

export const formatDate = (
  d: moment.Moment,
  format = 'MMMM Do YYYY, h:mm:ss a'
): string => {
  return d.format(format);
};

export const formatSmartRelativeDate = (
  d: moment.Moment | number | Date | string | firebase.firestore.Timestamp,
  format: string = HUMAN_DATE
): string => {
  const momentNow = moment(new Date());
  const momentDate =
    d instanceof firebase.firestore.Timestamp ? toMoment(d) : moment(d);
  const diff = momentNow.diff(momentDate, 'days');

  if (diff < 30) {
    return momentDate.fromNow();
  }
  return momentDate.format(format);
};

export type DateLike =
  | moment.Moment
  | number
  | Date
  | string
  | firebase.firestore.Timestamp;

export const dateLikeToMoment = (d: DateLike): moment.Moment => {
  if (d instanceof firebase.firestore.Timestamp) {
    return toMoment(d);
  }
  return moment(d);
};

export const formatDatePrecise = (
  d: DateLike,
  format = PRECISE_DATE
): string => {
  if (d instanceof firebase.firestore.Timestamp) {
    return formatDate(toMoment(d), format);
  }
  return formatDate(moment(d), format);
};

export const formatDateHuman = (
  d: DateLike,
  short: boolean = false
): string => {
  const f = short ? HUMAN_DATE_SHORT : HUMAN_DATE;
  if (d instanceof firebase.firestore.Timestamp) {
    return formatDate(toMoment(d), f);
  }
  return formatDate(moment(d), f);
};

export const formatDateRange = (
  start: DateLike,
  end: DateLike,
  format = HUMAN_DATE
) => `${formatDatePrecise(start, format)} - ${formatDatePrecise(end, format)}`;

export const toMoment = (d: firebase.firestore.Timestamp) => {
  return moment(d.toMillis());
};

export const fromMoment = (d: moment.Moment) => {
  return firebase.firestore.Timestamp.fromMillis(d.valueOf());
};

export const timeBetween = (
  start: firebase.firestore.Timestamp,
  end: firebase.firestore.Timestamp
) => {
  const startMoment = toMoment(start);
  const endMoment = toMoment(end);

  return endMoment.diff(startMoment, 'days');
};

export const now = (): firebase.firestore.Timestamp =>
  // firestore is not defined in tests - provide a mock Timestamp instead.
  firebase.firestore
    ? firebase.firestore.Timestamp.now()
    : {
        seconds: 0,
        nanoseconds: 0,
        toDate: () => new Date(),
        toMillis: () => 0,
        isEqual: (other) => other.toMillis() === 0,
        valueOf: () => ''
      };

export const nowRdb = () => {
  const ts = now();
  return { _seconds: ts.seconds, _nanoseconds: ts.nanoseconds };
};
export const tsFromRdb = (
  ts: { _seconds: number; _nanoseconds: number } | null
) => {
  if (!ts) {
    return null;
  }
  return new firebase.firestore.Timestamp(ts._seconds, ts._nanoseconds);
};

export const tsFromMillis = (ms: number) => {
  return firebase.firestore.Timestamp.fromMillis(ms);
};

export const nullableTsToIso = (ts: firebase.firestore.Timestamp | null) => {
  return ts?.toDate().toISOString() ?? null;
};

export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const weekdayToNumber = (weekday: string): number =>
  WEEKDAYS.indexOf(weekday);
export const numberToWeekday = (weekday: number): string => WEEKDAYS[weekday];

export const humanizeDuration = (ms: number, withSuffix = false) =>
  moment.duration(ms, 'ms').humanize(withSuffix);

export const getTimeElapsed = (start: moment.Moment, end: moment.Moment) => {
  const seconds = moment.duration(end.diff(start)).asSeconds();

  const minutes = Math.floor(seconds / 60).toFixed(0);
  const remainingSeconds = Math.round(seconds % 60).toFixed(0);

  return `${padStart(minutes, 2, '0')}:${padStart(remainingSeconds, 2, '0')}`;
};

export const compareTimestamps = (
  a: firebase.firestore.Timestamp,
  b: firebase.firestore.Timestamp
) => {
  const aMs = a.toMillis();
  const bMs = b.toMillis();
  if (aMs === bMs) {
    return 0;
  }
  return aMs < bMs ? -1 : 1;
};

export const isBefore = (
  a: firebase.firestore.Timestamp,
  b: firebase.firestore.Timestamp
) => {
  return a.toMillis() < b.toMillis();
};

export type Timestamp = firebase.firestore.Timestamp;
export type MomentRange = { start: moment.Moment; end: moment.Moment };
export type TimestampRange = { start: Timestamp; end: Timestamp };

export const timeframeToMomentRange = (tf: Timeframe): MomentRange => ({
  start: moment.tz(tf.start, DAY_FORMAT, tf.tz).startOf('d'),
  end: moment.tz(tf.end, DAY_FORMAT, tf.tz).startOf('d')
});

export const timeframeToTimestampRange = (tf: Timeframe): TimestampRange => {
  const r = timeframeToMomentRange(tf);
  return { start: fromMoment(r.start), end: fromMoment(r.end) };
};

export const msToSecs = (ms: number, digits = 4) => {
  const s = Math.floor(ms / 1000);
  const restMs = Math.round(ms % 1000);
  return `${parseFloat(`${s}.${padStart(`${restMs}`, 3, '0')}`).toFixed(
    digits
  )}s`;
};

export const msToMinsAndSecs = (ms: number) => {
  const seconds = Math.round(ms / 1000);
  const minutes = Math.floor(seconds / 60).toFixed(0);
  const remainingSeconds = Math.round(seconds % 60).toFixed(0);

  return `${padStart(minutes, 2, '0')}:${padStart(remainingSeconds, 2, '0')}`;
};

export const toCappedTimekey = (
  ts: firebase.firestore.Timestamp,
  tz: string
) => {
  const m = toMoment(ts).tz(tz);
  if (m.hour() < 12) {
    m.subtract(1, 'd');
  }
  return m.format(TIMEKEY_FORMAT);
};

// always inclusive on both sides
export const isBetweenMs = (x: number, start: number, end: number) => {
  return start <= x && x <= end;
};

export const isBetween = (
  x: moment.Moment,
  start: moment.Moment,
  end: moment.Moment
) => {
  return start.isSameOrBefore(x) && x.isSameOrBefore(end);
};

export const areOverlappingRanges = <T extends MomentRange>(a: T, b: T) => {
  if (a.start.isSame(b.start)) {
    return true;
  }
  if (a.start.isBefore(b.start) && a.end.isAfter(b.start)) {
    return true;
  }
  if (a.end.isSame(b.end)) {
    return true;
  }
  if (a.end.isAfter(b.end) && a.start.isBefore(b.end)) {
    return true;
  }
  if (isBetween(a.start, b.start, b.end)) {
    // with all other checks made before, we also know that
    // isBetween(a.end, b.start, b.end) === true;
    return true;
  }
  return false;
};

export const toEndOfPreviousDay = (m: moment.Moment) =>
  m.clone().subtract(1, 'd').endOf('d');
export const toStartOfNextDay = (m: moment.Moment) =>
  m.clone().add(1, 'd').startOf('d');

const TZ_CACHE: {
  [tz: string]: {
    [ms: string]: moment.Moment;
  };
} = {};

export const getMomentInTz = (tz: string, ms: number) => {
  const cache = (TZ_CACHE[tz] = TZ_CACHE[tz] || {});
  return (cache[ms] = cache[ms] || moment(ms).tz(tz));
};

export const timeframeToMoments = (timeframe: Timeframe) => {
  return {
    start: moment.tz(timeframe.start, DAY_FORMAT, timeframe.tz),
    end: moment.tz(timeframe.end, DAY_FORMAT, timeframe.tz)
  };
};

export const timeframeToMs = (timeframe: Timeframe) => {
  const moments = timeframeToMoments(timeframe);
  return {
    start: moments.start.valueOf(),
    end: moments.end.valueOf(),
    tz: timeframe.tz
  };
};

export const msToTimeframe = (ms: {
  start: number;
  end: number;
  tz: string;
}): Timeframe => {
  return {
    start: moment.tz(ms.start, ms.tz).format(DAY_FORMAT),
    end: moment.tz(ms.end, ms.tz).format(DAY_FORMAT),
    tz: ms.tz
  };
};

export const tsIsAfter = (a: Timestamp, b: Timestamp) => {
  return a.toMillis() > b.toMillis();
};

export const getTimeKeyRangeFromTimeframe = (
  timeframe: Timeframe
): string[] => {
  const start = moment.tz(timeframe.start, DAY_FORMAT, timeframe.tz);
  const end = moment.tz(timeframe.end, DAY_FORMAT, timeframe.tz);
  const range = Math.abs(start.diff(end, 'days'));
  const result: string[] = [];
  for (let i = 0; i < range; i++) {
    const x = start.clone().add(i, 'days').format(TIMEKEY_FORMAT);
    result.push(x);
  }
  return result;
};

export const tksToTimeframe = (
  start: string,
  end: string,
  tz: string
): Timeframe => {
  const s = moment(start, TIMEKEY_FORMAT);
  const e = moment(end, TIMEKEY_FORMAT);
  return {
    start: s.format(DAY_FORMAT),
    end: e.format(DAY_FORMAT),
    tz
  };
};

const diffDays = (start: moment.Moment, end: moment.Moment): number => {
  return Math.abs(start.diff(end, 'days'));
};

export const tkRangeFromTks = (start: string, end: string) => {
  const s = moment(start, TIMEKEY_FORMAT);
  const e = moment(end, TIMEKEY_FORMAT);

  const range = diffDays(s, e);
  const result: string[] = [];
  for (let i = 0; i <= range; i++) {
    const x = s.clone().add(i, 'days').format(TIMEKEY_FORMAT);
    result.push(x);
  }
  return result;
};

export const ZERO_TIMESTAMP = tsFromMillis(0);

export type ISOTimeRange = {
  start: string;
  end: string;
};

export const momentRangeToIsoRange = (x: MomentRange): ISOTimeRange => ({
  start: x.start.toISOString(),
  end: x.end.toISOString()
});

export const timeframeToIsoRange = (tf: Timeframe): ISOTimeRange => {
  return momentRangeToIsoRange(timeframeToMomentRange(tf));
};

export const useTimeframeToIsoRange = (tf: Timeframe): ISOTimeRange => {
  const { start, end, tz } = tf;
  return useMemo(() => timeframeToIsoRange({ start, end, tz }), [
    start,
    end,
    tz
  ]);
};

export const isoRangeToTimeframe = (
  range: ISOTimeRange,
  tz: string
): Timeframe => {
  const start = moment(range.start).tz(tz).format(DAY_FORMAT);
  const end = moment(range.end).tz(tz).format(DAY_FORMAT);
  return {
    start,
    end,
    tz
  };
};

export const secondsToHumanTime = (seconds: number) => {
  const duration = moment.duration(seconds, 'seconds');
  if (duration.asSeconds() < 60) {
    const secs = Math.floor(duration.asSeconds());

    return `${secs} ${pluralize('second', secs)}`;
  } else if (duration.asMinutes() < 60) {
    const mins = Math.floor(duration.asMinutes());
    return `${mins} ${pluralize('minute', mins)}`;
  } else if (duration.asHours() < 25) {
    const hours = Math.floor(duration.asHours());
    return `${hours} ${pluralize('hour', hours)}`;
  } else {
    const days = Math.floor(duration.asDays());
    return `${days} ${pluralize('day', days)}`;
  }
};

export const INTERVALS_TO_TK: Record<AnalyticsIntervalUnit, string> = {
  year: 'YYYY',
  month: 'YYYYMM',
  week: 'YYYYWW',
  quarter: 'YYYYMM',
  day: TIMEKEY_FORMAT,
  hour: 'YYYYDDDHH',
  minute: 'YYYYDDDHHmm'
};

const CH_DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export const intervalToTk = (
  interval: string,
  tz: string,
  unit: AnalyticsIntervalUnit = 'day'
) => {
  const m = moment.tz(interval, CH_DATE_FORMAT, tz);
  return m.format(INTERVALS_TO_TK[unit]);
};

export const MOMENT_TO_INTERVAL_TICK = {
  day: {
    startsInCurrentYear: (m: Moment) => m.format('MMM D'),
    startsInOtherYear: (m: Moment) => m.format("MMM D, 'YY")
  },
  hour: {
    startsInCurrentYear: (m: Moment) => m.format('MMM D, HH:mm'),
    startsInOtherYear: (m: Moment) => m.format("MMM D, 'YY, HH:mm")
  },
  minute: {
    startsInCurrentYear: (m: Moment) => m.format('MMM D, HH:mm'),
    startsInOtherYear: (m: Moment) => m.format("MMM D, 'YY, HH:mm")
  },
  week: {
    startsInCurrentYear: (m: Moment) => m.format('MMM D'),
    startsInOtherYear: (m: Moment) => m.format("MMM D, 'YY")
  },
  month: {
    startsInCurrentYear: (m: Moment) => m.format('MMM'),
    startsInOtherYear: (m: Moment) => m.format("MMM 'YY")
  },
  quarter: {
    startsInCurrentYear: (m: Moment) => `Q${m.quarter()}`,
    startsInOtherYear: (m: Moment) => `Q${m.quarter()} '${m.format('YY')}`
  },
  year: {
    startsInCurrentYear: (m: Moment) => m.format('YYYY'),
    startsInOtherYear: (m: Moment) => m.format('YYYY')
  }
};

export const tkToIntervalTick = (
  tk: string,
  intervalUnit: AnalyticsIntervalUnit,
  startsInCurrentYear: boolean
) => {
  const m = moment(tk, INTERVALS_TO_TK[intervalUnit]);
  const year = startsInCurrentYear
    ? 'startsInCurrentYear'
    : 'startsInOtherYear';
  return MOMENT_TO_INTERVAL_TICK[intervalUnit][year](m);
};

export const MOMENT_TO_INTERVAL_LABEL = {
  day: {
    startsInCurrentYear: (m: Moment) => m.format('ddd MMM D'),
    startsInOtherYear: (m: Moment) => m.format("ddd MMM D, 'YY")
  },
  hour: {
    startsInCurrentYear: (m: Moment) => m.format('MMM D, HH:mm'),
    startsInOtherYear: (m: Moment) => m.format("MMM D, 'YY, HH:mm")
  },
  minute: {
    startsInCurrentYear: (m: Moment) => m.format('MMM D, HH:mm'),
    startsInOtherYear: (m: Moment) => m.format("MMM D, 'YY, HH:mm")
  },
  week: {
    startsInCurrentYear: (m: Moment) => `Week of ${m.format('MMM D')}`,
    startsInOtherYear: (m: Moment) => `Week of ${m.format("MMM D, 'YY")}`
  },
  month: {
    startsInCurrentYear: (m: Moment) => m.format('MMM'),
    startsInOtherYear: (m: Moment) => m.format("MMM 'YY")
  },
  quarter: {
    startsInCurrentYear: (m: Moment) => `Q${m.quarter()}`,
    startsInOtherYear: (m: Moment) => `Q${m.quarter()} '${m.format('YY')}`
  },
  year: {
    startsInCurrentYear: (m: Moment) => m.format('YYYY'),
    startsInOtherYear: (m: Moment) => m.format('YYYY')
  }
};

export const tkToIntervalLabel = (
  tk: string,
  intervalUnit: AnalyticsIntervalUnit,
  startsInCurrentYear: boolean
) => {
  const m = moment(tk, INTERVALS_TO_TK[intervalUnit]);
  const year = startsInCurrentYear
    ? 'startsInCurrentYear'
    : 'startsInOtherYear';
  return MOMENT_TO_INTERVAL_LABEL[intervalUnit][year](m);
};

export const sqlTimestampToMoment = (ts: string, tz: string) => {
  return moment.tz(ts, 'YYYY-MM-DD HH:mm:ss', tz);
};

export const sqlTimestampToMomentOrNull = (
  ts: string | undefined | null,
  tz: string
) => {
  return ts ? moment.tz(ts, 'YYYY-MM-DD HH:mm:ss', tz) : null;
};

// Based on https://github.com/vercel/little-date/blob/700e291eeed52bd02a6d5e31faca6351d4820e7f/src/format-date-range.ts
// Handles only dates, not times. Look into original for inspiration on how to handle times.
export const prettifyDateRange = (from: Moment, to: Moment): string => {
  const today = moment().startOf('day');

  const sameYear = from.isSame(to, 'year');
  const sameMonth = from.isSame(to, 'month');
  const sameDay = from.isSame(to, 'day');
  const thisYear = from.isSame(today, 'year');

  const yearSuffix = thisYear ? '' : `, ${to.format('YYYY')}`;

  // Check if the range is the entire year
  // Example: 2023
  if (
    from.clone().startOf('year').isSame(from, 'minute') &&
    to.clone().endOf('year').isSame(to, 'minute')
  ) {
    return from.format('YYYY');
  }

  // Check if the range is an entire quarter
  // Example: Q1 2023
  if (
    from.clone().startOf('quarter').isSame(from, 'minute') &&
    to.clone().endOf('quarter').isSame(to, 'minute') &&
    from.quarter() === to.quarter()
  ) {
    return `Q${from.quarter()} ${from.format('YYYY')}`;
  }

  // Check if the range is across entire month
  if (
    from.clone().startOf('month').isSame(from, 'minute') &&
    to.clone().endOf('month').isSame(to, 'minute')
  ) {
    if (sameMonth && sameYear) {
      // Example: January 2023
      return from.format('MMMM YYYY');
    }
    // Example: Jan - Feb 2023
    return `${from.format('MMM')} – ${to.format('MMM YYYY')}`;
  }

  // Range across years
  // Example: Jan 1 '23 - Feb 12 '24
  if (!sameYear) {
    return `${from.format("MMM D 'YY")} – ${to.format("MMM D 'YY")}`;
  }

  // Range across months
  // Example: Jan 1 - Feb 12[, 2023]
  if (!sameMonth) {
    return `${from.format('MMM D')} – ${to.format('MMM D')}${yearSuffix}`;
  }

  // Range across days
  if (!sameDay) {
    // Example: Jan 1 - 12[, 2023]
    return `${from.format('MMM D')} – ${to.format('D')}${yearSuffix}`;
  }

  // Full day
  // Example: Fri, Jan 1[, 2023]
  return `${from.format('ddd, MMM D')}${yearSuffix}`;
};

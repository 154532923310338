import { Card, CardContent, styled, Typography } from '@material-ui/core';
import { truncate } from 'lodash';
import React from 'react';
import { DataGrid } from '../../../../admin/components/DataGrid';
import { LinkExternalWithEllipsis } from '../../../../components/LinkExternal';
import { Currency } from '../../../../components/Number';
import { IProductCatalogRowV0 } from '../../../../domainTypes/productCatalog';
import { FlexContainerVertical } from '../../../../layout/Flex';

const ProductImage = styled('img')((p) => ({
  objectFit: 'contain',
  objectPosition: 'top',
  width: '100%',
  height: '100%'
}));

const InnerGrid = styled('div')((p) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 4fr',
  gridColumnGap: p.theme.spacing(2),
  alignItems: 'flex-start'
}));

const replaceLeadingZeros = (x: string) => x.replace(/^0+/, '');

const DataGridOuter = styled('div')((p) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: p.theme.spacing(5),
  gridRowGap: p.theme.spacing(2)
}));

export const ProductCatalogRowCard = ({ d }: { d: IProductCatalogRowV0 }) => {
  return (
    <Card>
      <CardContent>
        <InnerGrid>
          <ProductImage src={d.image_link} />
          <FlexContainerVertical fullWidth>
            <FlexContainerVertical fullWidth spacing={0}>
              <Typography variant="caption">
                <strong>{d.title}</strong>
              </Typography>
              <Typography variant="caption">
                <LinkExternalWithEllipsis href={d.link} color="primary">
                  {truncate(d.link, { length: 100 })}
                </LinkExternalWithEllipsis>
              </Typography>
            </FlexContainerVertical>
            <DataGridOuter>
              <DataGrid
                items={[
                  ['Brand', d.brand],
                  ['UID', d.uid]
                ]}
              />
              <DataGrid
                items={[
                  [
                    'Identifier',
                    replaceLeadingZeros(d.gtin || d.ean || d.upc || '-')
                  ],
                  ['Price', <Currency cents={d.price_usd} currency="USD" />]
                ]}
              />
              <DataGrid
                items={[
                  ['GTIN', d.gtin || '-'],
                  ['EAN', d.ean || '-'],
                  ['UPC', d.upc || '-']
                ]}
              />
              <DataGrid
                items={[
                  ['MPN', d.mpn || '-'],
                  ['ASIN', d.asin || '-'],
                  ['ISVN', d.isbn || '-']
                ]}
              />
            </DataGridOuter>
          </FlexContainerVertical>
        </InnerGrid>
      </CardContent>
    </Card>
  );
};

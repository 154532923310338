import { Doc } from './document';
import { Timestamp } from './time';
export type Frequency = None | Daily | Weekly;

export type None = {
  type: 'NONE';
};

export type Daily = {
  type: 'DAILY';
  hour: number;
  tz: string;
};

export type Weekly = {
  type: 'WEEKLY';
  weekDay: number; // 0 === Sunday
  hour: number;
  tz: string;
};

export type LastRun = {
  date: Timestamp;
  status: 'OK' | 'ERROR';
};

export interface IAbstractSchedule {
  spaceId: string;
  type: Type;
  frequency: Frequency;
  lastRun: LastRun | null;
  nextRun: Timestamp | null;

  createdAt: Timestamp;
  createdBy: string;

  active: boolean;
  ignoreSpaceLockout?: boolean;
}

// add new schedules below

export type Type =
  | 'PRODUCT_SCAN'
  | 'PRODUCT_SCAN_REMOVAL'
  | 'REPORT'
  | 'TRIAL_REMINDER'
  | 'SALES_API_FETCH'
  | 'DAILY_EARNINGS_REPORT'
  | 'DAILY_EARNINGS_REPORT2'
  | 'DATA_EXPORT'
  | 'LINK_CHECK';

export interface ProductScanSchedule extends IAbstractSchedule {
  type: 'PRODUCT_SCAN';
  config: {};
}

export interface DataExportSchedule extends IAbstractSchedule {
  type: 'DATA_EXPORT';
  config: {
    exportType: 'CLICKS' | 'TRANSACTIONS' | 'CUSTOM';
    destination: 'HOSTED_GCS_BUCKET';
    bucket: string;
    path: string;
  };
}

export interface ProductScanRemovalSchedule extends IAbstractSchedule {
  type: 'PRODUCT_SCAN_REMOVAL';
  config: {
    scanId: string;
  };
}

export interface ReportSchedule extends IAbstractSchedule {
  type: 'REPORT';
  config: {};
}

export interface TrialReminderSchedule extends IAbstractSchedule {
  type: 'TRIAL_REMINDER';
  config: {};
}

export interface SalesApiFetchSchedule extends IAbstractSchedule {
  type: 'SALES_API_FETCH';
  config: {
    handler: string;
  };
}

/**
 * @deprecated
 * Use DailyEarningsReport2Schedule instead
 */
export interface DailyEarningsReportSchedule extends IAbstractSchedule {
  type: 'DAILY_EARNINGS_REPORT';
  config: {};
}

export interface DailyEarningsReport2Schedule extends IAbstractSchedule {
  type: 'DAILY_EARNINGS_REPORT2';
  config: {};
}

export interface LinkCheckSchedule extends IAbstractSchedule {
  type: 'LINK_CHECK';
  config: {};
}

export type Schedule =
  | ProductScanSchedule
  | ProductScanRemovalSchedule
  | ReportSchedule
  | TrialReminderSchedule
  | SalesApiFetchSchedule
  | DailyEarningsReportSchedule
  | DailyEarningsReport2Schedule
  | LinkCheckSchedule
  | DataExportSchedule;

export const isOfScheduleType = <T extends { type: Type }, TValue extends Type>(
  doc: Doc<T>,
  type: TValue
): doc is Doc<Extract<T, { type: TValue }>> => {
  return doc.data.type === type;
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@material-ui/core';
import { isEqual, sortBy } from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { CheckboxSimple } from '../../../../../components/CheckboxSimple';
import { Chip } from '../../../../../components/Chip';
import { DeleteButton } from '../../../../../components/DeletionConfirmation';
import { DialogActionsWithSlots } from '../../../../../components/DialogActionsWithSlots';
import { DialogTitleWithTypography } from '../../../../../components/DialogTitleWithTypography';
import {
  IDENTITY_SORTER,
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { LinkExternal } from '../../../../../components/LinkExternal';
import { Loader } from '../../../../../components/Loader';
import { Monospace } from '../../../../../components/Monospace';
import { SelectionBox } from '../../../../../components/SelectionBox';
import { IColumn } from '../../../../../components/Table/Column';
import { Doc, generateToDocFn } from '../../../../../domainTypes/document';
import {
  EmbeddingsProcessAbortArgs,
  EmbeddingsProcessCheckArgs,
  EmbeddingsProcessLoadArgs,
  EmbeddingsProcessStartArgs,
  EmpbeddingsRequest,
  ProductCatalogEmbeddingsProcess,
  ProductCatalogEmbeddingsProcessSettings,
  PRODUCT_CATALOG_EMBEDDINGS_PROCESS_SETTINGS_ID
} from '../../../../../domainTypes/productCatalog';
import { useDialogState } from '../../../../../hooks/useDialogState';
import { useModel } from '../../../../../hooks/useModel';
import { useSet } from '../../../../../hooks/useSet';
import { CanvasBar } from '../../../../../layout/Canvas';
import { Centered } from '../../../../../layout/Centered';
import {
  FlexContainer,
  FlexContainerVertical
} from '../../../../../layout/Flex';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { useStringQueryParam } from '../../../../../routes';
import {
  removeDoc,
  setDoc,
  store,
  updateDoc
} from '../../../../../services/db';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../../../services/firecache/collectionListener';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../../../../../services/firecache/documentListener';
import { toLogLink } from '../../../../../services/logging';
import { fromMoment } from '../../../../../services/time';
import { DataGrid, DATA_GRID_SPACER } from '../../../../components/DataGrid';
import { Json } from '../../../../components/Json';
import { toFirestoreConsoleFromDoc } from '../../../../services/firebase';
import { publishInstruction } from '../../../../services/pubsub';

const getLogLinkForProcess = (d: Doc<ProductCatalogEmbeddingsProcess>) => {
  return toLogLink(
    {
      kind: 'product-catalog-embeddings-logger',
      processId: d.id
    },
    {},
    {
      aroundTime: fromMoment(moment(d.data.startedAt).add(6, 'days')),
      duration: 'P7D'
    }
  );
};

const toLink = (href: string) => <LinkExternal color="primary" href={href} />;

const toEmbeddingsProcessDoc = generateToDocFn<
  ProductCatalogEmbeddingsProcess
>();
const collection = () =>
  store().collection('productCatalogEmbeddingsProcessV1');
const processStore = createCollectionListenerStore(
  () => new CollectionListener(collection(), toEmbeddingsProcessDoc)
);
const processByIdStore = createDocumentListenerGetter(
  (processId) => collection().doc(processId),
  toEmbeddingsProcessDoc
);

const useProcesses = () => useCollectionListener(processStore(''));
const useProcess = (processId: string) =>
  useDocumentListener(processByIdStore(processId));

const toEmbeddingsProcessSettingsDoc = generateToDocFn<
  ProductCatalogEmbeddingsProcessSettings
>();
const settingsStore = createDocumentListenerGetter(
  (id) =>
    store().collection('productCatalogEmbeddingsProcessSettingsV1').doc(id),
  toEmbeddingsProcessSettingsDoc,
  () => ({
    check: {
      autoRun: false,
      maxRequests: 1_000_000,
      execute: false
      // maxTokens - currently we're just limited by how many we ask for, as each request has so few tokens
    },
    load: {
      autoRun: false,
      maxParallel: 1,
      chunksPerInvocation: 3,
      execute: false
    }
  })
);
const useSettings = () =>
  useDocumentListener(
    settingsStore(PRODUCT_CATALOG_EMBEDDINGS_PROCESS_SETTINGS_ID)
  );

const StatusChip = ({ d }: { d: Doc<ProductCatalogEmbeddingsProcess> }) => {
  return (
    <Chip
      label={d.data.step}
      variant={d.data.running ? 'default' : 'outlined'}
      type={
        d.data.step === 'COMPLETE'
          ? 'SUCCESS'
          : d.data.step === 'ERROR'
          ? 'ERROR'
          : d.data.step === 'ABORTED'
          ? 'ABORTED'
          : d.data.step === 'INIT'
          ? 'NONE'
          : 'PENDING'
      }
    />
  );
};

const Labels = ({ labels }: { labels: string[] }) => {
  return (
    <FlexContainer spacing={0.5}>
      {labels.map((l, i) => (
        <Chip
          key={i}
          size="small"
          label={l}
          variant={l === 'auto' ? 'default' : 'outlined'}
        />
      ))}
    </FlexContainer>
  );
};
type D = Doc<ProductCatalogEmbeddingsProcess>;
const COLUMNS: IColumn<D, string>[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => (
      <Typography variant="caption">
        <Monospace>{d.id}</Monospace>
      </Typography>
    ),
    align: 'left',
    width: 95,
    flexGrow: 0
  },
  {
    key: 'step',
    head: () => 'Step',
    cell: (d) => <StatusChip d={d} />,
    align: 'left',
    width: 75
  },
  {
    key: 'catalogs',
    head: () => 'Catalogs',
    cell: (d) => d.data.requestData.catalogs.join(', '),
    align: 'left',
    width: 200
  },
  {
    key: 'labels',
    head: () => 'Labels',
    cell: (d) => <Labels labels={d.data.labels} />,
    align: 'left',
    width: 75
  },
  {
    key: 'provider',
    head: () => 'Provider',
    cell: (d) => d.data.requestData.provider,
    align: 'left',
    width: 75
  },
  {
    key: 'embeddingsRequested',
    head: () => '#',
    cell: (d) => d.data.requestData.count,
    align: 'right',
    width: 75
  },
  {
    key: 'chunks',
    head: () => 'T - P/E/C/L',
    cell: (d) => {
      const batches = Object.values(d.data.requests);
      const total = batches.length;
      const pending = batches.filter((b) => b.status === 'PENDING').length;
      const errored = batches.filter((b) => b.status === 'ERROR').length;
      const completed = batches.filter((b) => b.status === 'COMPLETE').length;
      const loaded = batches.filter((b) => b.status === 'LOADED').length;

      return `${total} - ${pending}/${errored}/${completed}/${loaded}`;
    },
    align: 'right',
    width: 150
  },
  {
    key: 'startedAt',
    head: () => 'Started At',
    cell: (d) => moment(d.data.startedAt).utc().format('YYYY-MM-DD HH:mm'),
    align: 'right',
    width: 130,
    sortable: true
  },
  {
    key: 'updatedAt',
    head: () => 'Updated At',
    cell: (d) =>
      d.data.updatedAt
        ? moment(d.data.updatedAt).utc().format('YYYY-MM-DD HH:mm')
        : '-',
    align: 'right',
    width: 130,
    sortable: true
  },
  {
    key: 'Finished',
    head: () => 'Finished At',
    cell: (d) =>
      d.data.finishedAt
        ? moment(d.data.finishedAt).utc().format('YYYY-MM-DD HH:mm')
        : '-',
    align: 'right',
    width: 130,
    sortable: true
  },
  {
    key: 'other',
    head: () => '',
    cell: (d) => (
      <LinkExternal color="primary" href={getLogLinkForProcess(d)}>
        Logs
      </LinkExternal>
    ),
    align: 'right',
    width: 40,
    sortable: false
  }
];

const rowToKey = (d: D) => d.id;

const SORTERS: ItemSorters<D> = {
  startedAt: {
    key: 'startedAt',
    items: { sort: (d) => new Date(d.data.startedAt).valueOf(), dir: 'desc' }
  },
  updatedAt: {
    key: 'updatedAt',
    items: {
      sort: (d) =>
        d.data.updatedAt ? new Date(d.data.updatedAt).valueOf() : Infinity,
      dir: 'desc'
    }
  },
  finishedAt: {
    key: 'finishedAt',
    items: {
      sort: (d) =>
        d.data.finishedAt ? new Date(d.data.finishedAt).valueOf() : Infinity,
      dir: 'desc'
    }
  }
};
const DEFAULT_SORTER = SORTERS.startedAt;

const TOPICS = {
  start: 'product-catalog-embeddings-process-start',
  check: 'product-catalog-embeddings-process-check',
  load: 'product-catalog-embeddings-process-load',
  abort: 'product-catalog-embeddings-process-abort'
};

const publishStart = async (args: EmbeddingsProcessStartArgs) => {
  await publishInstruction({
    topic: TOPICS.start,
    payload: args
  });
};

const publishCheck = async (args: EmbeddingsProcessCheckArgs) => {
  await publishInstruction({
    topic: TOPICS.check,
    payload: args
  });
};

const publishLoad = async (args: EmbeddingsProcessLoadArgs) => {
  await publishInstruction({
    topic: TOPICS.load,
    payload: args
  });
};

const publishAbort = async (args: EmbeddingsProcessAbortArgs) => {
  await publishInstruction({
    topic: TOPICS.abort,
    payload: args
  });
};

const ProcessTabOverview = ({
  d
}: {
  d: Doc<ProductCatalogEmbeddingsProcess>;
}) => {
  return (
    <Section>
      <FlexContainerVertical fullWidth spacing={2}>
        <DataGrid
          items={[
            ['Firestore', toLink(toFirestoreConsoleFromDoc(d))],
            ['Retrieval Process ID', d.data.retrievalProcessId],
            DATA_GRID_SPACER,
            ['Step', <StatusChip d={d} />],
            ['Started at', d.data.startedAt],
            ['Updated at', d.data.updatedAt || '-'],
            ['Finished at', d.data.finishedAt || '-'],
            DATA_GRID_SPACER
          ]}
        />
      </FlexContainerVertical>
    </Section>
  );
};

const FILE_COLUMNS: IColumn<
  EmpbeddingsRequest,
  string,
  {
    selection: {
      selected: Set<string>;
      areAllSelected: boolean;
      selectAll: () => void;
      selectOne: (id: string, nextState: boolean) => void;
      selectNone: () => void;
    };
  }
>[] = [
  {
    key: 'selection',
    head: (p) => {
      const { areAllSelected, selectNone, selectAll, selected } = p!.selection;
      const onChange = () => (areAllSelected ? selectNone() : selectAll());
      return (
        <SelectionBox
          onChange={onChange}
          indeterminate={!areAllSelected && !!selected.size}
          value={areAllSelected}
        />
      );
    },
    alternateHead: () => 'Selection',
    cell: (d, { selection }) => {
      const isSelected = selection.selected.has(d.id);
      return (
        <SelectionBox
          onChange={() => selection.selectOne(d.id, !isSelected)}
          value={isSelected}
        />
      );
    },
    sortable: false,
    align: 'center',
    width: 50,
    flexGrow: 0
  },
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => (
      <Typography variant="caption">
        <Monospace>{d.id}</Monospace>
      </Typography>
    ),
    align: 'left',
    width: 300,
    flexGrow: 1
  },
  {
    key: 'status',
    head: () => 'Status',
    cell: (d) => (
      <Chip
        label={d.status}
        variant={d.status === 'LOADED' ? 'default' : 'outlined'}
        type={
          d.status === 'COMPLETE'
            ? 'PENDING'
            : d.status === 'ERROR'
            ? 'ERROR'
            : d.status === 'LOADED'
            ? 'SUCCESS'
            : 'NONE'
        }
      />
    ),
    align: 'center',
    width: 200,
    flexGrow: 0
  },
  {
    key: 'rows',
    head: () => 'Rows',
    cell: (d) => d.count,
    align: 'right',
    width: 120,
    flexGrow: 0
  },
  {
    key: 'provider',
    head: () => 'Provider',
    cell: (d) => d.provider,
    align: 'right',
    width: 120,
    flexGrow: 0
  }
];

const ProcessTabFiles = ({
  d
}: {
  d: Doc<ProductCatalogEmbeddingsProcess>;
}) => {
  const sel = useSet<string>();
  const files = sortBy(Object.values(d.data.requests), (x) => x.id);
  if (!files.length) {
    return <Centered>No request files (yet).</Centered>;
  }
  return (
    <RowsRenderer
      variant="contained"
      rows={files}
      columns={FILE_COLUMNS}
      rowToKey={(x) => x.id}
      sorter={IDENTITY_SORTER}
      sortDirection={'asc'}
      renderHead
      otherProps={{
        selection: {
          selectOne: (id, nextState) => (nextState ? sel.add(id) : sel.rm(id)),
          selectNone: () => sel.clear(),
          selectAll: () => sel.replace(new Set(files.map((d) => d.id))),
          selected: sel.set,
          areAllSelected: sel.set.size === files.length
        }
      }}
    />
  );
};

const ProcessDialog = ({
  open,
  onClose,
  d
}: {
  open: boolean;
  onClose: () => void;
  d: Doc<ProductCatalogEmbeddingsProcess>;
}) => {
  const [selectedTab, setSelectedTab] = useState('overview');
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitleWithTypography variant="h6">
        <FlexContainer justifyContent="space-between">
          <div>
            <StatusChip d={d} /> {d.data.requestData.catalogs.join(', ')} -{' '}
            {d.id}
          </div>
          <div>
            <Button
              target="_blank"
              href={getLogLinkForProcess(d)}
              size="small"
              color="primary"
            >
              Open Logs
            </Button>
          </div>
          <FlexContainer>
            <ButtonWithPromise
              size="small"
              variant="outlined"
              disabled={!d.data.running}
              pending="Updateing..."
              onClick={() => updateDoc(d, () => ({ running: false }))}
            >
              Set to not running
            </ButtonWithPromise>
            <ButtonWithPromise
              size="small"
              variant="contained"
              color="primary"
              disabled={d.data.step !== 'INIT'}
              pending="Starting..."
              onClick={() => publishStart({ processId: d.id })}
            >
              Start
            </ButtonWithPromise>
            <ButtonWithPromise
              size="small"
              variant="contained"
              color="primary"
              pending="Checking..."
              onClick={() =>
                publishCheck({
                  processId: d.id
                })
              }
            >
              Check
            </ButtonWithPromise>
            <ButtonWithPromise
              size="small"
              variant="contained"
              color="primary"
              pending="Loading..."
              onClick={() =>
                publishLoad({
                  processId: d.id
                })
              }
            >
              Load
            </ButtonWithPromise>
            <ButtonWithPromise
              size="small"
              variant="contained"
              pending="Aborting..."
              onClick={() => publishAbort({ processId: d.id })}
            >
              Abort
            </ButtonWithPromise>
          </FlexContainer>
        </FlexContainer>
      </DialogTitleWithTypography>
      <DialogContent>
        <FlexContainerVertical fullWidth spacing={3}>
          <Tabs
            value={selectedTab}
            onChange={(_, v: string) => setSelectedTab(v)}
          >
            <Tab label="Overview" value="overview" />
            <Tab label="Files" value="files" />
            <Tab label="JSON" value="json" />
          </Tabs>

          {selectedTab === 'overview' && <ProcessTabOverview d={d} />}
          {selectedTab === 'files' && <ProcessTabFiles d={d} />}
          {selectedTab === 'json' && <Json fullWidth data={d} />}
        </FlexContainerVertical>
      </DialogContent>
      <DialogActionsWithSlots
        left={
          <>
            <DeleteButton
              onDelete={() => {
                onClose();
                return removeDoc(d);
              }}
              variant="outlined"
              color="secondary"
            >
              Delete
            </DeleteButton>
          </>
        }
        right={
          <>
            <Button onClick={onClose}>Close</Button>
          </>
        }
      />
    </Dialog>
  );
};

const SinglePage = ({
  processId,
  onClose
}: {
  processId: string;
  onClose: () => void;
}) => {
  const [d, loading] = useProcess(processId);

  if (loading) {
    return (
      <Dialog open={true} fullScreen onClose={onClose}>
        <DialogContent>
          <Loader height="100%" />
        </DialogContent>
        <DialogActionsWithSlots
          right={<Button onClick={onClose}>Close</Button>}
        />
      </Dialog>
    );
  }

  if (d) {
    return <ProcessDialog open={true} onClose={onClose} d={d} />;
  }

  return (
    <Dialog open={true} fullScreen onClose={onClose}>
      <DialogContent>Something went wrong</DialogContent>
      <DialogActionsWithSlots
        right={<Button onClick={onClose}>Close</Button>}
      />
    </Dialog>
  );
};

const SettingsDialog = ({
  open,
  onClose,
  settings
}: {
  open: boolean;
  onClose: () => void;
  settings: Doc<ProductCatalogEmbeddingsProcessSettings>;
}) => {
  const [model, setModel] = useModel(settings.data);
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Embeddings Process Settings</DialogTitle>
      <DialogContent>
        <FlexContainerVertical spacing={6} fullWidth>
          <FlexContainerVertical fullWidth>
            <strong>Checking</strong>
            <CheckboxSimple
              color="primary"
              label="Run auto check"
              checked={model.check.autoRun}
              onChange={(autoRun) =>
                setModel((x) => ({
                  ...x,
                  check: { ...x.check, autoRun }
                }))
              }
            />

            <CheckboxSimple
              label="Execute auto check"
              checked={model.check.execute}
              onChange={(execute) =>
                setModel((x) => ({
                  ...x,
                  check: { ...x.check, execute }
                }))
              }
            />

            <TextField
              fullWidth
              variant="outlined"
              label="Max Requests"
              type="number"
              value={model.check.maxRequests}
              onChange={(ev) =>
                setModel((x) => ({
                  ...x,
                  check: {
                    ...x.check,
                    maxRequests: +ev.target.value
                  }
                }))
              }
            />
          </FlexContainerVertical>
          <FlexContainerVertical fullWidth>
            <strong>Loading</strong>
            <CheckboxSimple
              color="primary"
              label="Run auto load"
              checked={model.load.autoRun}
              onChange={(autoRun) =>
                setModel((x) => ({
                  ...x,
                  load: { ...x.load, autoRun }
                }))
              }
            />

            <CheckboxSimple
              label="Execute auto load"
              checked={model.load.execute}
              onChange={(execute) =>
                setModel((x) => ({
                  ...x,
                  load: { ...x.load, execute }
                }))
              }
            />

            <TextField
              fullWidth
              variant="outlined"
              label="Max parallel loads"
              type="number"
              value={model.load.maxParallel}
              onChange={(ev) =>
                setModel((x) => ({
                  ...x,
                  load: {
                    ...x.load,
                    maxParallel: +ev.target.value
                  }
                }))
              }
            />

            <TextField
              fullWidth
              variant="outlined"
              label="Max chunks per load invocation"
              type="number"
              value={model.load.chunksPerInvocation}
              onChange={(ev) =>
                setModel((x) => ({
                  ...x,
                  load: {
                    ...x.load,
                    chunksPerInvocation: +ev.target.value
                  }
                }))
              }
            />
          </FlexContainerVertical>
        </FlexContainerVertical>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <ButtonWithPromise
          variant="contained"
          color="primary"
          pending="Saving..."
          disabled={isEqual(model, settings.data)}
          onClick={() =>
            setDoc({
              ...settings,
              data: model
            })
          }
        >
          Save
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};

const ListPage = ({
  setSelectedProcessId
}: {
  setSelectedProcessId: (processId: string) => void;
}) => {
  const [ds] = useProcesses();
  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);

  const [settings] = useSettings();
  const settingsD = useDialogState();

  return (
    <Page width="FULL">
      <>
        {!ds && <Loader height={500} />}
        {ds && (
          <>
            <CanvasBar>
              <div />
              <FlexContainer>
                <Button onClick={settingsD.openDialog}>Settings...</Button>
                <ButtonWithPromise
                  pending="Checking..."
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    const eligible = ds.filter(
                      (d) => d.data.step === 'PROCESSING' && !d.data.running
                    );
                    if (eligible) {
                      await Promise.all(
                        eligible.map((x) => publishCheck({ processId: x.id }))
                      );
                    }
                  }}
                >
                  Check all
                </ButtonWithPromise>
              </FlexContainer>
            </CanvasBar>
            <RowsRenderer
              variant="contained"
              rows={ds}
              columns={COLUMNS}
              rowToKey={rowToKey}
              sorter={sorter || DEFAULT_SORTER}
              sortDirection={sorter ? dir : DEFAULT_SORTER.items.dir}
              onHeadClick={(c, d) =>
                setSort([SORTERS[c.key] || DEFAULT_SORTER, d])
              }
              chunkSize={100}
              rootMargin="400px"
              rowHeight={ROW_HEIGHTS.dense}
              renderHead
              otherProps={undefined}
              onRowClick={(d) => setSelectedProcessId(d.id)}
            />
          </>
        )}

        {settings && (
          <SettingsDialog
            open={settingsD.dialogOpen}
            onClose={settingsD.closeDialog}
            settings={settings}
          />
        )}
      </>
    </Page>
  );
};

export const PageProductCatalogEmbeddingProcesses = () => {
  const [selectedProcessId, setSelectedProcessId] = useStringQueryParam(
    'process',
    ''
  );

  if (selectedProcessId) {
    return (
      <SinglePage
        processId={selectedProcessId}
        onClose={() => setSelectedProcessId('')}
      />
    );
  }

  return <ListPage setSelectedProcessId={setSelectedProcessId} />;
};

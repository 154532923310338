import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { ChevronDown, Columns as IconColumns } from 'react-feather/dist';
import { MultiSelector } from '../../MultiSelector';
import { IColumn } from '../Column';

export interface ColumnSelectorProps<Key extends string> {
  value: Set<Key>;
  onChange: (nextValue: Set<Key>) => void;
  columns: IColumn<any, Key, any>[];
  short?: boolean;
}

export const ColumnSelector = <Key extends string>({
  value,
  onChange,
  columns
}: ColumnSelectorProps<Key>) => {
  const options = useMemo(
    () =>
      columns
        .map((c) => ({
          label: c.alternateHead ? c.alternateHead() : c.head(),
          value: c.key
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
    [columns]
  );
  return (
    <MultiSelector
      value={value}
      onChange={onChange}
      options={options}
      legend={`Columns (${value.size} of ${options.length} selected)`}
      allowSearch
    >
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px'
        }}
      >
        <IconColumns size={18} /> Columns{' '}
        <strong>
          {value.size} of {options.length}
        </strong>
        <ChevronDown size={18} />
      </Typography>
    </MultiSelector>
  );
};

import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import { last, mapValues, sortBy, sum } from 'lodash';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AlertBox } from '../../../components/AlertBox';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { Chip } from '../../../components/Chip';
import { DeleteButton } from '../../../components/DeletionConfirmation';
import { DialogActionsHeader } from '../../../components/DialogActionsHeader';
import { DialogActionsWithSlots } from '../../../components/DialogActionsWithSlots';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../components/GroupableList';
import { Loader } from '../../../components/Loader';
import { Monospace } from '../../../components/Monospace';
import { IColumn } from '../../../components/Table/Column';
import { Doc, generateToDocFn } from '../../../domainTypes/document';
import {
  ISOString,
  RealtimeEtlProcessPageViewsAndClicks
} from '../../../domainTypes/etl';
import { css } from '../../../emotion';
import { FlexContainer } from '../../../layout/Flex';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import { useStringQueryParam } from '../../../routes';
import {
  batchUpdateDocs,
  removeDoc,
  store,
  updateDoc
} from '../../../services/db';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import { DataGrid } from '../../components/DataGrid';
import { Json } from '../../components/Json';
import { LinkExternal } from '../../components/LinkExternal';
import { publishInstruction } from '../../services/pubsub';
import {
  getCloudStorageDirLink,
  getCloudStorageLink
} from '../../services/storage';

const triggerLoad = (d: Doc<RealtimeEtlProcessPageViewsAndClicks>) => {
  return Promise.all([
    publishInstruction({
      topic: 'dataMigrations-pageViewsToClickhouse-realTime-pageViews-load',
      payload: {
        processId: d.id,
        files: Object.values(d.data.loadFiles.pageViews).map((f) => f.fileName),
        execute: true
      }
    }),
    publishInstruction({
      topic: 'dataMigrations-pageViewsToClickhouse-realTime-clicks-load',
      payload: {
        processId: d.id,
        files: Object.values(d.data.loadFiles.clicks).map((f) => f.fileName),
        execute: true
      }
    })
  ]);
};

const toRealtimeEtlProcessDoc = generateToDocFn<
  RealtimeEtlProcessPageViewsAndClicks
>();
const collection = () =>
  store().collection('realtimeEtlProcessPageViewsAndClicksV1');

const CUTOFF = moment.utc().startOf('d').subtract(1, 'd');

const etlStore = createCollectionListenerStore(
  () =>
    // bit hardcoded - last two calendar days at most
    new CollectionListener(
      collection().where('insertedAt', '>', CUTOFF.toISOString()),
      toRealtimeEtlProcessDoc
    )
);
const useRealtimeEtlProcesses = () => useCollectionListener(etlStore(''));

const EltStatusChip = ({
  d
}: {
  d: Doc<RealtimeEtlProcessPageViewsAndClicks>;
}) => {
  return (
    <Chip
      label={d.data.step}
      type={
        d.data.step === 'DONE'
          ? 'SUCCESS'
          : d.data.step === 'EXTRACT' || d.data.step === 'LOAD'
          ? 'PENDING'
          : d.data.step === 'ERROR'
          ? 'ERROR'
          : 'NONE'
      }
    />
  );
};

const numberFormatter = new Intl.NumberFormat();

const Labels = ({ labels }: { labels: string[] }) => {
  return (
    <FlexContainer spacing={0.5}>
      {labels.map((l, i) => (
        <Chip
          key={i}
          size="small"
          label={l}
          variant={l === 'auto' ? 'default' : 'outlined'}
        />
      ))}
    </FlexContainer>
  );
};

const getPageViewsSum = (d: Doc<RealtimeEtlProcessPageViewsAndClicks>) => {
  return sum(Object.values(d.data.loadFiles.pageViews).map((f) => f.count));
};
const getClicksSum = (d: Doc<RealtimeEtlProcessPageViewsAndClicks>) => {
  return sum(Object.values(d.data.loadFiles.clicks).map((f) => f.count));
};

type D = Doc<RealtimeEtlProcessPageViewsAndClicks>;
const COLUMNS: IColumn<D, string>[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => (
      <Typography variant="caption">
        <Monospace>{d.id}</Monospace>
      </Typography>
    ),
    align: 'left',
    width: 95,
    flexGrow: 0
  },
  {
    key: 'step',
    head: () => 'Step',
    cell: (d) => {
      return <EltStatusChip d={d} />;
    },
    align: 'left',
    width: 75
  },
  {
    key: 'labels',
    head: () => 'Labels',
    cell: (d) => <Labels labels={d.data.labels} />,
    align: 'left',
    width: 75
  },
  {
    key: 'at',
    head: () => 'Run Date',
    cell: (d) => moment(d.data.insertedAt).utc().format('YYYY-MM-DD HH:mm'),
    align: 'left',
    width: 150,
    sortable: true
  },
  {
    key: 'rangeStart',
    head: () => 'Range Start',
    cell: (d) => {
      const m = moment(d.data.range.start).utc();
      return (
        <span>
          {m.format('YYYY-MM-DD')} <strong>{m.format('HH:mm')}</strong>
        </span>
      );
    },
    align: 'left',
    width: 150,
    sortable: true
  },
  {
    key: 'rangeEnd',
    head: () => 'Range End',
    cell: (d) => moment(d.data.range.end).utc().format('YYYY-MM-DD HH:mm'),
    align: 'left',
    width: 150,
    sortable: true
  },
  {
    key: 'newLinks',
    head: () => 'New Links',
    cell: (d) => (
      <div>
        {[d.data.newLinks.counts.total, d.data.newLinks.counts.skipped].join(
          '/'
        )}
      </div>
    ),
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'events',
    head: () => 'Events',
    cell: (d) => {
      const pvEvents = getPageViewsSum(d);
      const clickEvents = getClicksSum(d);
      return (
        <div>
          {[pvEvents, clickEvents]
            .map((n) => numberFormatter.format(n))
            .join('/')}
        </div>
      );
    },
    align: 'right',
    width: 140,
    flexGrow: 0
  }
];

const SORTERS: ItemSorters<D> = {
  at: {
    key: 'at',
    items: { sort: (d) => new Date(d.data.insertedAt).valueOf(), dir: 'desc' }
  },

  rangeStart: {
    key: 'rangeStart',
    items: { sort: (d) => new Date(d.data.range.start).valueOf(), dir: 'desc' }
  },
  rangeEnd: {
    key: 'rangeEnd',
    items: { sort: (d) => new Date(d.data.range.end).valueOf(), dir: 'desc' }
  }
};
const DEFAULT_SORTER = SORTERS.rangeStart;

const rowToKey = (d: D) => d.id;

const ProcessDialog = ({
  open,
  onClose,
  d
}: {
  open: boolean;
  onClose: () => void;
  d: Doc<RealtimeEtlProcessPageViewsAndClicks>;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogActionsHeader
        onClose={onClose}
        left={
          <>
            <EltStatusChip d={d} />
            <Labels labels={d.data.labels} />
            <Typography variant="h6">
              {d.id} - {d.data.range.start} {'->'} {d.data.range.end}
            </Typography>
          </>
        }
      />
      <DialogContent>
        <Section>
          {d.data.skipOnReload && (
            <AlertBox variant="error">
              This process will be skipped on a full reload.
            </AlertBox>
          )}
          <DataGrid
            items={[
              ['Started at', d.data.insertedAt],
              ['Finished at', d.data.finishedAt || <div />],
              [
                'Cloud Storage Dir',
                <LinkExternal
                  href={getCloudStorageDirLink(d.data.cloudStorageDir)}
                >
                  {d.data.cloudStorageDir}
                </LinkExternal>
              ],
              ['New Links', d.data.newLinks.counts.total],
              ['New Links (skipped)', d.data.newLinks.counts.skipped],
              ['Page Views', getPageViewsSum(d)],
              ['Clicks', getClicksSum(d)]
            ]}
          />
        </Section>
        {Object.values(d.data.loadFiles.pageViews).map((f) => {
          if (f.status === 'ERROR') {
            return (
              <div key={f.fileName}>
                <LinkExternal href={getCloudStorageLink(f.fileName)}>
                  {f.fileName} - {f.count}
                </LinkExternal>
              </div>
            );
          }
          return null;
        })}

        <Json
          data={d.data}
          shouldCollapse={(x) =>
            x.name === 'clicks' ||
            x.name === 'pageViews' ||
            x.name === 'loadFiles'
          }
        />
      </DialogContent>
      <DialogActionsWithSlots
        left={
          <DeleteButton
            variant="outlined"
            color="secondary"
            onDelete={async () => {
              await removeDoc(d);
            }}
          >
            Delete
          </DeleteButton>
        }
        right={
          <FlexContainer justifyContent="flex-end">
            {(d.data.step === 'INIT' || d.data.step === 'EXTRACT') && (
              <ButtonWithPromise
                variant="outlined"
                color="primary"
                pending="Starting..."
                onClick={async () => {
                  await publishInstruction({
                    topic:
                      'dataMigrations-pageViewsToClickhouse-realTime-extract',
                    payload: {
                      range: d.data.range,
                      labels: d.data.labels,
                      execute: true,
                      advanceToNextStep: true
                    }
                  }).then(() => {
                    enqueueSnackbar(
                      'This will create a new process item. Remove the stuck one manually afterwards'
                    );
                  });
                }}
              >
                Retry
              </ButtonWithPromise>
            )}
            {d.data.step === 'PRE_LOAD' && (
              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="Triggering..."
                onClick={() => triggerLoad(d)}
              >
                Load
              </ButtonWithPromise>
            )}
            {d.data.step === 'DONE' && (
              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="Triggering..."
                disabled={d.data.skipOnReload}
                onClick={async () => {
                  await updateDoc(d, (x) => ({
                    step: 'PRE_LOAD' as const,
                    loadFiles: {
                      pageViews: mapValues(x.loadFiles.pageViews, (f) => ({
                        ...f,
                        status: 'PENDING' as const
                      })),
                      clicks: mapValues(x.loadFiles.clicks, (f) => ({
                        ...f,
                        status: 'PENDING' as const
                      }))
                    }
                  }));
                }}
              >
                Revert to pre-load
              </ButtonWithPromise>
            )}
          </FlexContainer>
        }
      />
    </Dialog>
  );
};

const addToIsoDate = (t: ISOString, value: number, unit: 'h' | 'w' | 'd') => {
  return moment(t).utc().add(value, unit).toISOString();
};

const CatchUpMode = ({
  ds
}: {
  ds: Doc<RealtimeEtlProcessPageViewsAndClicks>[];
}) => {
  const [state, setState] = useState<{
    active: boolean;
  }>({ active: false });

  const ref = useRef({
    scheduled: false
  });

  useEffect(() => {
    if (!state.active) {
      return;
    }
    const isExtracting = !!ds.find(
      (d) => d.data.step === 'INIT' || d.data.step === 'EXTRACT'
    );
    if (!isExtracting && !ref.current.scheduled) {
      console.log('SCHEDULE EXTRACT');
      const start = last(sortBy(ds, (d) => d.data.range.end))?.data.range.end;
      if (!start) {
        return;
      }
      const end = addToIsoDate(start, 1, 'h');

      // TODO This is off by one hour - it runs one process too much, don't know why
      if (new Date(end).valueOf() > Date.now()) {
        console.log('FULLY CAUGHT UP');
        setState({ active: false });
      }
      const range = { start, end };
      setTimeout(() => {
        publishInstruction({
          topic: 'dataMigrations-pageViewsToClickhouse-realTime-extract',
          payload: {
            range,
            labels: ['catchup'],
            execute: true,
            advanceToNextStep: true
          }
        }).then(() =>
          console.log(`EXTRACT STARTED - ${range.start} -> ${range.end}`)
        );
      }, 2000); // wait a bit to give some breathing room
      ref.current.scheduled = true;
    }

    if (isExtracting) {
      ref.current.scheduled = false;
    }
  }, [ds, state]);

  return (
    <FlexContainer justifyContent="flex-start">
      {state.active && (
        <>
          <Chip type="SUCCESS" label="CATCHUP RUNNING" />
          <Button
            onClick={() => setState((x) => ({ ...x, active: false }))}
            variant="outlined"
          >
            Deactivate
          </Button>
        </>
      )}
      {!state.active && (
        <>
          <Chip type="NONE" label="CATCHUP STOPPED" />
          <Button
            onClick={() => setState((x) => ({ ...x, active: true }))}
            variant="outlined"
          >
            {`Activate > next start ${
              last(sortBy(ds, (d) => d.data.range.end))?.data.range.end
            }`}
          </Button>
        </>
      )}
    </FlexContainer>
  );
};

const getRowClassName = (d: D) => {
  if (!d.data.skipOnReload) {
    return '';
  }
  return css(() => ({
    opacity: 0.3
  }));
};

const Body = ({ ds }: { ds: Doc<RealtimeEtlProcessPageViewsAndClicks>[] }) => {
  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  const [selectedProcessId, setSelectedProcessId] = useStringQueryParam(
    'process',
    ''
  );
  const selectedProcess = useMemo(
    () => ds.find((d) => d.id === selectedProcessId) || null,
    [selectedProcessId, ds]
  );

  return (
    <Page width="L">
      <Section>
        <FlexContainer justifyContent="space-between">
          <CatchUpMode ds={ds} />
          <DeleteButton
            dialogTitle="Reload all?"
            buttonAction="Reload"
            keyword="RELOAD"
            prompt="Are you sure you want to prepare for re-load, then type:"
            onDelete={async () => {
              const toReload = ds.filter((d) => !d.data.skipOnReload);
              console.log(toReload.length);
              await batchUpdateDocs(ds, (d) => ({
                step: 'PRE_LOAD' as const,
                loadFiles: {
                  pageViews: mapValues(d.loadFiles.pageViews, (f) => ({
                    ...f,
                    status: 'PENDING' as const
                  })),
                  clicks: mapValues(d.loadFiles.clicks, (f) => ({
                    ...f,
                    status: 'PENDING' as const
                  }))
                }
              }));
            }}
          >
            Set to PRE_LOAD
          </DeleteButton>
        </FlexContainer>
        <AlertBox variant="pending">All times shown are UTC</AlertBox>
        <RowsRenderer
          variant="contained"
          rows={ds}
          columns={COLUMNS}
          rowToKey={rowToKey}
          sorter={sorter || DEFAULT_SORTER}
          sortDirection={sorter ? dir : DEFAULT_SORTER.items.dir}
          onHeadClick={(c, d) => setSort([SORTERS[c.key] || DEFAULT_SORTER, d])}
          chunkSize={100}
          rootMargin="400px"
          rowHeight={ROW_HEIGHTS.dense}
          rowToClassName={getRowClassName}
          renderHead
          otherProps={undefined}
          onRowClick={(d) => setSelectedProcessId(d.id)}
        />
      </Section>

      {selectedProcess && (
        <ProcessDialog
          open={true}
          onClose={() => setSelectedProcessId('')}
          d={selectedProcess}
        />
      )}
    </Page>
  );
};

export const PageRealtimeProcesses = () => {
  const [ds] = useRealtimeEtlProcesses();
  if (!ds) {
    return <Loader height={300} />;
  }
  return <Body ds={ds} />;
};

import { pick } from 'lodash';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DAY_FORMAT, isDayString } from './domainTypes/analytics';
import { EMPTY_ARR } from './domainTypes/emptyConstants';
import { createRoutes, Query } from './domainTypes/routes';
import { useRouter } from './hooks/useRouter';
import { MomentRange } from './services/time';
import {
  DEFAULT_SEPARATOR,
  ListSeparator
} from './domainTypes/routes/query-params';

let collectedQueryParams: Query = {};

const withoutEmpty = (query: Query) =>
  Object.keys(query).reduce((m, k) => {
    const v = query[k];
    if (v && v.length) {
      m[k] = v;
    }
    return m;
  }, {} as Query);

const withQuery = (url: string, params: Query) => {
  const queryParams = withoutEmpty(params);
  return `${url}${
    Object.keys(queryParams).length
      ? `?${queryString.stringify(queryParams)}`
      : ''
  }`;
};

export const useRoutes = () => {
  const { history, match, location } = useRouter();

  const create = () => {
    const getQuery = () => queryString.parse(location.search);
    collectedQueryParams = { ...collectedQueryParams, ...getQuery() };

    const getPath = () => location.pathname;
    const getParams = <T = any>() => match.params as T;
    const goTo = (url: string, replace = false) =>
      replace ? history.replace(url) : history.push(url);

    const changeQuery = (queryParams: Query) => {
      // explicit changes need to update the collectedQueryParams, so that
      // params can be blanked. Otherwise they would stick around!
      collectedQueryParams = { ...collectedQueryParams, ...queryParams };
      return goTo(withQuery(getPath(), { ...getQuery(), ...queryParams }));
    };

    const toUrl = (
      path: string,
      query: Query = {},
      persistedQueryParams: string[] = []
    ) => ({
      path,
      query: {
        // If page A wants to pick param X, but moving to a page which
        // ignores param X, we want to set it again when we come back to
        // X. Hence we collect all query params over time, so that we
        // can always re-establish them for pages which are interested in them.
        ...pick(collectedQueryParams, [
          ...persistedQueryParams,
          'spaceId',
          'dataProvider',
          'experimental'
        ]),
        ...query
      }
    });

    const ROUTES = createRoutes(toUrl, queryString.stringify);
    return { getPath, getParams, getQuery, goTo, changeQuery, ROUTES };
  };

  const [v, setV] = useState(create());
  useEffect(() => {
    setV(create());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, match, location]);
  return v;
};

export const useQueryParam = <T>(
  param: string,
  fromParam: (p: string | null | undefined) => T,
  toParam: (v: T) => string | undefined, // undefined removes the param
  dependencies: any[] = EMPTY_ARR
): [T, (nextValue: T) => void, (nextValue: T) => Query] => {
  const { getQuery, changeQuery } = useRoutes();
  let p = getQuery()[param];

  if (Array.isArray(p)) {
    console.log(`Param ${param} is array, expected a single value`);
    p = p[0];
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => fromParam(p as string | null | undefined), [
    p,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...dependencies
  ]);

  const setValue = useCallback(
    (v: T) =>
      changeQuery({
        [param]: toParam(v)
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [p, changeQuery]
  );

  return [value, setValue, (v) => ({ [param]: toParam(v) })];
};

export const useBooleanQueryParam = (param: string, defaultValue: boolean) => {
  return useQueryParam<boolean>(
    param,
    (t) => (t ? t === 'true' : defaultValue),
    (t) => `${t}`
  );
};

export const useStringQueryParam = (param: string, defaultValue = '') =>
  useQueryParam<string>(
    param,
    (t) => t || defaultValue,
    (t) => t
  );

export const useTypedStringQueryParam = <T extends string>(
  param: string,
  defaultValue: T,
  blankDefault?: boolean
): [T, (nextValue: T) => void] => {
  const [value, setValue] = useQueryParam<T>(
    param,
    (t) => (t as T) || defaultValue,
    (t) => (blankDefault && t === defaultValue ? undefined : t)
  );

  return [value, setValue];
};

export const useNumberQueryParam = (param: string, defaultValue = 0) =>
  useQueryParam<number>(
    param,
    (t) => (t ? parseInt(t, 10) : defaultValue),
    (t) => `${t}`
  );

export const useComboQueryParam = <T>(
  param: string,
  fromParam: (p: [string | undefined, string | undefined]) => T,
  toParam: (v: T) => [string | undefined, string | undefined],
  delimiter = '---',
  dependencies: any[] = EMPTY_ARR
) =>
  useQueryParam(
    param,
    (p) => {
      const [a, b] = (p || '').split(delimiter);
      return fromParam([a, b]);
    },
    (v) => toParam(v).join(delimiter),
    dependencies
  );

export const setToQueryParam = (
  xs: Set<string>,
  separator: ListSeparator = DEFAULT_SEPARATOR
): string => [...xs].sort().join(separator);

export const queryParamToList = <T extends string>(
  x: string,
  separator: ListSeparator = DEFAULT_SEPARATOR
): T[] => x.split(separator) as T[];

export const queryParamToSet = <T extends string>(
  x: string,
  separator: ListSeparator = DEFAULT_SEPARATOR
): Set<T> => new Set(queryParamToList(x, separator));

export const useNullableStringSetQueryParam = (
  param: string,
  separator: ListSeparator = DEFAULT_SEPARATOR
) => {
  return useQueryParam(
    param,
    (p) => (p ? queryParamToSet(p, separator) : null),
    (v) => (v ? setToQueryParam(v, separator) : undefined)
  );
};

export const useDayRangeQueryParam = (
  param: string

  // fromParam: (p: [string | undefined, string | undefined]) => MomentRange,
  // toParam: (v: MomentRange | null) => [string | undefined, string | undefined],
) => {
  const delimiter = '---';
  const f = DAY_FORMAT;

  return useQueryParam<MomentRange | null>(
    param,
    (p) => {
      if (!p) {
        return null;
      }
      const [start = '', end = ''] = p.split(delimiter);
      if (isDayString(start) && isDayString(end)) {
        return {
          start: moment(start, f).startOf('d'),
          end: moment(end, f).startOf('d')
        };
      }
      return null;
    },
    (r) => {
      if (!r) {
        return undefined;
      }
      return `${r.start.format(DAY_FORMAT)}${delimiter}${r.end.format(
        DAY_FORMAT
      )}`;
    }
  );
};
